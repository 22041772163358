import React from 'react'
import { Box, Text, Link } from 'theme-ui'
import PoweredByGatsby from '@components/PoweredByGatsby'
import { HeaderLogo } from '../Header/Header.Logo'
import Popover from '../../../components/Popover'

const styles = {
  logo: {
    pb: 1,
    mb: 2,
    mt: [4, 0],
  },
  copyright: {
    pt: 2,
    mb: [2, 4],
  },
}

export const FooterLogo = () => (
  <>
    <Box sx={styles.logo}>
      <HeaderLogo grayscale />
    </Box>
    <Box sx={styles.copyright}>
      © {new Date().getFullYear()}, Hak cipta dilindungi undang-undang.
    </Box>
    <Box>
      <PoweredByGatsby />
    </Box>
    <Popover title='Icon Attribution'>
      <Box>
        <Text>
          <strong>Icon for categories</strong>
        </Text>
        <Text as='span'>
          <em>Bahasa Inggris</em>:{' '}
        </Text>
        <Text as='span'>
          Icons made by{' '}
          <Link href='https://www.flaticon.com/authors/freepik' title='Freepik'>
            Freepik
          </Link>{' '}
          from{' '}
          <Link href='https://www.flaticon.com/' title='Flaticon'>
            www.flaticon.com
          </Link>
        </Text>
      </Box>
      <Box>
        <Text as='span'>
          <em>Blogging</em>:{' '}
        </Text>
        <Text as='span'>
          Icons made by{' '}
          <Link href='https://www.flaticon.com/authors/freepik' title='Freepik'>
            Freepik
          </Link>{' '}
          from{' '}
          <Link href='https://www.flaticon.com/' title='Flaticon'>
            www.flaticon.com
          </Link>
        </Text>
      </Box>
      <Box>
        <Text as='span'>
          <em>Komputer</em>:{' '}
        </Text>
        <Text as='span'>
          Icons made by{' '}
          <Link
            href='https://www.flaticon.com/authors/dave-gandy'
            title='Dave Gandy'
          >
            Dave Gandy
          </Link>{' '}
          from{' '}
          <Link href='https://www.flaticon.com/' title='Flaticon'>
            www.flaticon.com
          </Link>
        </Text>
      </Box>
      <Box>
        <Text as='span'>
          <em>Pemrograman</em>:{' '}
        </Text>
        <Text as='span'>
          Icons made by{' '}
          <Link href='https://www.flaticon.com/authors/freepik' title='Freepik'>
            Freepik
          </Link>{' '}
          from{' '}
          <Link href='https://www.flaticon.com/' title='Flaticon'>
            www.flaticon.com
          </Link>
        </Text>
      </Box>
      <Box>
        <Text as='span'>
          <em>Random</em>:{' '}
        </Text>
        <Text as='span'>
          Icons made by{' '}
          <Link href='https://www.flaticon.com/authors/freepik' title='Freepik'>
            Freepik
          </Link>{' '}
          from{' '}
          <Link href='https://www.flaticon.com/' title='Flaticon'>
            www.flaticon.com
          </Link>
        </Text>
      </Box>
    </Popover>
  </>
)
