import text from '@elegantstack/flow-ui-theme/src/theme/text'

export default {
  ...text,
  em: {
    fontFamily: 'Sriracha',
    color: 'error',
    fontStyle: 'normal'
  }
}
