import React from 'react'
import { Card, Box, Text } from 'theme-ui'
import Reveal from '@components/Reveal'

const styles = {
  attribution: {
    color: 'steelblue',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  popover: {
    maxWidth: '75%',
    position: 'absolute',
    top: '30%',
    display: 'none',
    padding: '10px',
    zIndex: '999',
  },
}

export const Popover = ({ title, children }) => {
  const popover = React.createRef()
  const handleFocus = (e) => {
    popover.current.style.display = 'block'
  }
  const handleBlur = (e) => {
    popover.current.style.display = 'none'
  }
  return (
    <>
      <Box>
        <Text as='span' sx={styles.attribution} onMouseOver={handleFocus}>
          {title}
        </Text>
      </Box>
      <Reveal>
        <Card
          ref={popover}
          onMouseOver={handleFocus}
          onMouseOut={handleBlur}
          sx={styles.popover}
        >
          {children}
        </Card>
      </Reveal>
    </>
  )
}

export default Popover
